$rounded-corners: false;
$buttonPadding: 1.2rem 3rem;
$gap-size: 2;
$fontDefault: 'Rubik';
$gap-size: 2;
@mixin width($width: 100%, $gap: $gap-size, $math: null, $max-width: false, $min-width: false, $column: null){
  // $math = - + * /
  @supports (-webkit-appearance:none) {
    $calculation: #{$column} - 1;

    @if $max-width == true {
      @if $column{
        max-width: calc((#{$width} - (#{$calculation}) * #{$gap}rem ) / #{$column});
        width: $width;
      }@else if $math {
        max-width:calc(#{$width $math $gap});
        width: $width;
      } @else{
        max-width:calc(#{$width} - #{$gap}rem);
      }
    }@else if $min-width == true {
      @if $math {
        min-width:calc(#{$width $math $gap});
      } @else{
        min-width:calc(#{$width} - #{$gap}rem);
      }
    }@else if ($max-width == false , $min-width == false) {
      @if $math {
        width:calc(#{$width $math $gap}rem);
      }@else {
        width: calc(#{$width} - #{$gap}rem);
      }
      @if $column{
        width: calc((#{$width} - (#{$calculation}) * #{$gap}rem ) / #{$column});
      }
    }@else {
      width: calc(#{$width} - #{$gap}rem);
    }

    @media not all and (min-resolution:.001dpcm) {
      /*&:nth-of-type(n) {
        margin: {
          right: $gap + rem;
          bottom: $gap + rem;
        }
      }*/
      &:nth-of-type(#{$column}n) {
        margin-right: 0;
      }
    }
  }
}
@mixin content($type: null, $mobile: null){
  @if ($type == inner) {
    max-width: $mediumContent;
  }
  @if ($type == outer) {
    max-width: $contentWidth;
  }
  margin: {
    left: auto;
    right: auto;
  }
  width: 100%;
  padding: {
    left: 2rem;
    right: 2rem;
  }
  @if ($mobile == nopadding) {
    @include breakpoint(tablet){
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
}
@mixin safari{
  @media not all and (min-resolution:.001dpcm) {
    @content;
  }
}
@mixin flex($justify: null, $align: null, $direction: null, $wrap: null, $flex: true,  $gap: false, $order: null, $number: null) {
    @if $flex {
      display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;      /* TWEENER - IE 10 */
      display: -webkit-flex;     /* NEW - Chrome */
      display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
    }@else{
    }
    @if $gap == true {
      gap: $gap-size + rem;
    }@else if $gap == false{
    }@else{
      gap: $gap + rem;
    }
    @if $order {
      -webkit-box-ordinal-group: $order;   /* OLD - iOS 6-, Safari 3.1-6 */
      -moz-box-ordinal-group: $order;      /* OLD - Firefox 19- */
      -ms-flex-order: $order;              /* TWEENER - IE 10 */
      -webkit-order: $order;               /* NEW - Chrome */
      order: $order;
    }@else{
    }
    @if $justify == centery{
      align-items: center;
      justify-content: center;
    } @if $justify == flex-endy{
      align-items: flex-end;
      justify-content: flex-end;
    } @if $justify == flex-starty{
      align-items: flex-start;
      justify-content: flex-start;
    } @if $justify == space-betweeny{
      align-items: flex-start;
      justify-content: space-between;
    } @else{
      justify-content: $justify;
      align-items: $align;
    }
    flex-direction: $direction;
    @if $wrap == true {
      flex-wrap: wrap;
    }@else if $wrap == false{
      flex-wrap: nowrap;
    }@else {
    }
}
@mixin fontIcon( $sizeValue: 2.2, $iconWeight:  900, $color: null, $line-height: null) {
  font-family: $fontIcon;
  font-weight: $iconWeight;
  font-size: $sizeValue + rem;
  color: $color;
  line-height: $line-height;
}

// ** fontDefault **  font-size($sizeValue), line-height($lineHeight), color($fontColor), font-weight($fontWeight)
@mixin fontDefault( $sizeValue: $fontDefault, $lineHeight: $lineHeightDefault, $color: $colorFontDefault, $weight: null, $transform: null, $style: null, $fontStyle:null, $letterSpacing: null, $font: false) {
  @if ($sizeValue != null) {
    font-size: $sizeValue + rem;
  }
  /* font-size */
  @if ($lineHeight != null) {
    line-height: $lineHeight ;
  }
  color: $color;
  font-weight: $weight;
  text-transform: $transform;
  text-decoration: $style;
  font-style: $fontStyle;
  letter-spacing: $letterSpacing;
  @if $font == true{
    font-family: $fontDefault;
  }@else if $font == $fontSpecial{
    font-family: $fontSpecial;
  }
}

@mixin overlay( $directionV: 0, $directionH: 0, $width: 100%, $height: 100%, $position: absolute, $right: false, $bottom: false, $top: false, $left: false, $z-index: null){
    position: $position;
    height: $height;
    width: $width;
    z-index: $z-index;
    @if $directionV == top {
      top: 0;
    } @else if $directionV == bottom {
      bottom: 0;
    } @else if $top {
      top: $directionV;
    } @else if $bottom {
      bottom: $directionV;
    } @else {
      top: $directionV;
    }
    @if $directionH == left {
      left: 0;
    } @else if $directionH == right {
      right: 0;
    } @else if $left {
      left: $directionH;
    } @else if $right {
      right: $directionH;
    } @else {
      left: $directionH;
    }
}

/* default :1px solid $colorBorder */
@mixin border($size: 1px, $color: $colorBorder, $weight: solid,
$top: false, $bottom: false, $left: false, $right: false,
$radius: null) {
  @if  $top or $bottom or $left or $right {
    @if $top == true {
      border-top: $size $weight $color;
    }
    @if $bottom == true {
      border-bottom: $size $weight $color;
    }
    @if $left == true {
      border-left: $size $weight $color;
    }
    @if $right == true {
      border-right: $size $weight $color;
    }
  }@else if $size == '0' or $size == 'none'{
    border: none;
  } @else {
    border: $size $weight $color;
  }
  @if $radius {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
  }
}

@mixin boxShadow($hLength: null, $vLength:null, $blur: null, $spread: null, $color: null){
  -webkit-box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
  -moz-box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
  box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
}

@mixin transition($all: all, $effect: ease, $duration: .2s) {
  -webkit-transition: $all $effect $duration;
  -o-transition: $all $effect $duration;
  transition: $all $effect $duration;
}

