@import "utils/variables";
@import "utils/mixin";
@import "utils/breakpoints";
@import "base/default";
@import "base/typography";

@include breakpoint(largeDesktop){
    .inner-main-container {
        &.middle {
            display: none;
        }
    }
}
@include breakpoint(desktop){
    .header {
        &-top {
            &-container {
                ul {
                    gap: 2rem
                }
            }
        }
        &-bottom {
            @include flex($wrap: true, $gap: 1.8);
            &-search {
                width: 100%;
            }
        }
    }
}
@include breakpoint(smallDesktop){
    .header {
        &-main {
            &-seals {
                display: none;
            }
            &-account {
                padding-right: 2rem;
                a {
                    span {
                        display: none;
                    }
                }
            }
            .inner-main-container {
                &.right {
                    .header-main-logoalt {
                        margin-left: 2rem;
                        margin-right: 2rem;
                    }
                }
            }
        }
        &-bottom-navigation {
            width: 3rem;
            &.desktop{
                display:none;
            }
            &.mobile{
                @include flex(null, center);
            }
        }
        &-bottom {
            &-search {
                width: calc(100% - 6rem);
            }
        }
        &-top {
            &-container{
                .left{
                    display: none;
                }
                .right{
                    margin-left: auto;
                }
            }
        }
    }
    .minicart-wrapper{
        a{
            .text{
                display: none;
            }
        }
    }
    .page {
        &-wrapper{
            left: 0;
            position: relative;
            @include transition;
        }
        &-main {

            .checkout-index-index & {
                margin-top: 0;
            }
        }
        &-layout {
            &-2columns-left {
                .columns {
                    @include flex($wrap: true);
                }
            }
        }
    }
    .filter{
        padding: 0;
    }
    .inner-container{
        @include flex($wrap: true);
    }
    .footer{
        &-top-service{
            @include width($gap: 27);
        }
        &-main{
            .section{
                width: 100%;
                padding-bottom: 4rem;
            }
            &-products, &-links{
                margin-top: 4rem;
            }
        }
    }
    .to-top {
        &.active {
            bottom: 2rem;
        }
    }
}
@include breakpoint(tablet){
    .header {
        &-top{
            &-container{
                i{
                    display: none;
                }
            }
            &-hotline{
                .phone{
                    bottom: -3rem;
                    i{
                        @include flex;
                    }
                }
                a{

                }
            }
            &-links{
                ul{
                    @include flex($wrap: true, $gap: 0);

                    li{
                        padding-right: 2rem;
                    }
                }
            }
        }
    }
    .block-title {
        h2 {
            font-size: 2.8rem;
            padding-bottom: 2rem;
            line-height: normal;
            margin-bottom: 0;
            span{
                width: auto;
                display: inline-block;
            }
        }
    }
    .toolbar {
        &-products {
            width: 100%;
            @include flex($wrap: false);
        }
        .limiter, .sorter{
            label{
                width: 100%;
                font-size: 1.5rem;
                font-weight: bold;
                text-align: left;
            }
            .control{
                width: 100%;
                @include flex;
            }
            select{
                width: 100%;
                height: 4.3rem;
                font-size: 1.5rem;
            }
        }
        .sorter{
            margin-left: 0;
            padding-left: 0;

        }
        .limiter{
            order: 1;
        }
    }
    .product{
        &-info{
            &-buttons{
                display:none;
            }
        }
    }
    .page-layout {
        &-2columns {
            &-left, &-right {
                .columns {
                    width: 100%;
                    .column {
                        width: 100%;
                    }
                }
            }
        }
    }
    .inner-container{
        @include flex($wrap: true);
    }
    .limiter-text{
        white-space: nowrap;
    }
    .checkout-agreement, .billing-address-same-as-shipping-block {
        @include flex(null, flex-start, $wrap: false);
        label{
            font-size: 1.3rem;
        }
        input{
            margin-top: .5rem;
        }
    }
    .filter{
        @include flex($wrap: true);
        &-options{
            @include flex($wrap: true);
            &-item{
                margin-top: 2rem;
                margin-left: 0;
                background: $colorWhite;
                border-radius: .4rem;
                border:1px solid $colorBorder;
                max-width: 100%;
            }
            &-content{
                position: relative;
                padding-top: 0;
            }

        }
        &-content{
            padding-left: 0;
            display:none;
        }
        &-title{
            width: 100%;
            padding-bottom: 2rem;
        }
    }
    .footer{
        &-top-service{
            @include width($gap: 0);
        }
        &-main{
            &-seals{
                &.section{
                    padding-bottom: 0;
                }
            }
            .section{
                width: 100%;
            }
        }
        &-bottom-hotline{
            display: none;
        }
    }
}
@include breakpoint(phone){
    .header{
        &-top-hotline{
            li{
                &:first-child{
                    display: none;
                }
            }
        }
        &-main{
            .inner-main-container {
                &.right {
                    .header-main-logoalt {
                        display:none;
                    }
                }
            }
        }
        &-bottom{
            &-links{
                display: none;
            }
            &-search{
                width: calc(100% - 6rem);
            }
        }
    }
    .breadcrumbs .item{
        font-size: 1.2rem;
        &.home{
            display: none;
        }
        &:nth-of-type(2){
           /* display: none;*/
        }
    }
    #shipping-new-address-form > .field{
        width: 100%;
    }
    .page {
        &-main{
            .actions-toolbar{
                @include flex($wrap: true);
            }
        }
    }
    .block-title h2{
        width: 100%;
    }
    .filter {
        &-value{
            width: 100%;
        }
        &-current {
            &-subtitle {
                width: 100%;
                text-align: center;
                padding-right: 0;
                padding-bottom: 2rem;
            }
            &-wrap {
                @include flex($wrap: true);
                .item {
                    width: 100%;
                    @include flex;
                    margin-bottom: 1.5rem;
                    .action{
                        width: auto;
                    }
                    &s {
                        width: 100%;
                        @include flex($wrap: true);
                        justify-content: center;
                    }
                }
            }
        }
    }
    .footer{
        &-top-imprint{
            .inner-container > ul:last-child{
                max-width: 100%;
            }
        }
        &-main-seals ul{
            @include flex($wrap: true);
            li{
                max-width: 100%;
                img{
                    max-width: 5rem;
                }
                &:last-child{
                    img{
                        max-width: initial;
                    }
                }
            }
        }
        &-copyright{
            @include border(0);
        }
    }
    .toolbar{
        .limiter, .sorter{
            label, .limiter-text{
                display: none;
            }
            select{
                width: auto;
            }
        }
    }

}
