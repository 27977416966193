// Font-family
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-Light.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-Light.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-Light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-Regular.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-Regular.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-Medium.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-Medium.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-SemiBold.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-SemiBold.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-SemiBold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-Bold.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-Bold.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-ExtraBold.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-ExtraBold.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-ExtraBold.svg') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik';
    src: url('../../fonts/Rubik/Rubik-Black.woff2') format('woff2'),
    url('../../fonts/Rubik/Rubik-Black.woff') format('woff'),
    url('../../fonts/Rubik/Rubik-Black.svg') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

body {
    @include fontDefault($font: true);
    @include breakpoint(smallDesktop){
        word-break: break-word;
        hyphens: auto;
        @include fontDefault(1.6);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {

    text-align: left;
    color: $colorPrimary;
    word-break: break-word;
    hyphens: auto;
}
@mixin title($sizeH1) {
    @if $sizeH1 == homeTitleSlider {
        @include fontDefault(7.9, 7.2rem, $colorWhite, 600);

        text-transform: uppercase;
    } @else if $sizeH1 == defaultTitle{
        @include fontDefault(3, 3.6rem, $weight: 600);

        text-align: left;
    }@else if $sizeH1 == page-title-wrapper{
        @include fontDefault(3.7, 4.5rem, inherit, 600);
        text-align: center;
    }
}
h1 {
    @include title(defaultTitle);
    margin-bottom: 0;
    @include breakpoint(tablet){
        font-size: 3rem;
        line-height: 1.2;
        margin: 0 0 2rem 0;
    }
}

h2 {
    @include fontDefault(2.4, 2.4rem);
    font-weight: 400;
    &:first-of-type{
        padding-top: 0;
    }
    @include breakpoint(tablet){
        font-size: 2rem;
        line-height: normal;
    }
}
h3 {
    @include fontDefault(1.9, 3.3rem);
    font-weight: 700;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
    color: $colorSecondary;
    text-transform: uppercase;
}
h4 {
    @include fontDefault(2, 2rem);
    font-weight: 600;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: 480px) {
        padding-bottom: 0.9rem;
    }
}

a {
    color: $colorPrimary;
    text-decoration: none;
}

b,
strong {
    font-weight: 600;
}

ol,
ul {
    margin: 0;
    padding: 0 0 0 0;
    list-style: none;
}
p{
    @include fontDefault($color: $colorPrimary);
    margin-bottom: .8rem;
}
small, .small{
    font-size: 1.2rem;
}