*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}
html, body{
  min-height: 100vh;
}
html {
  font-size: 62.5%;
}
i{
  @include fontIcon;
  font-style: normal;
}
img{
  max-width: 100%;
  height: auto;
  line-height: normal;
  image-rendering: -webkit-optimize-contrast;
}
body {
  box-sizing: border-box;
  @include fontDefault(1.6, normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::selection {
  background-color: $colorPrimary;
  color: $colorWhite;
}
.mobile{
  display: none;
}
.cart-empty{
  a {
    color: $colorSecondary;
  }
}
.cms-no-route, .cms-page-view{
    .main{
      .inner-container {
        ul {
          margin: 1rem 0;
          padding-left: 2rem;
        }

        li {
          list-style: disc;
        }

        a {
          color: $colorSecondary;
        }
      }
    }
}
.disc{
  margin: 1rem 0;
  padding-left: 2rem;
  li{
    list-style: disc;
  }
  a{
    color: $colorSecondary;
  }
}
@include breakpoint(tablet){
  .mobile{
    display: block;
  }
  .desktop, img.main{
    display: none;
    &:only-child{
      display: inline-block;
    }
  }
}v
body.cms-no-route .columns{
  @include content(outer);
  margin-bottom: 13rem;
  padding-top: 4.5rem;
  a {
    font-weight: bold;
  }
}
.column-main {
  position: relative;
}