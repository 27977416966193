// width
$maxWidth: 192rem;
$contentWidth: 156rem;
$mediumContent: 112rem;
$smallContent: 65rem;

// breakpoints
$largeDesktop: 1500px;
$desktop: 1200px;
$smallDesktop: 1024px;
$tablet: 768px;
$phone: 480px;
$smallPhone: 360px;

// images
$imageDir: "../../images/";
// images
$imageDirResp: "../images/";
// icons
$iconDir: "../../images/icons/";

// Font-size
$fontDefault: 1.8;
$fontTable:1.6;
$fontButton:1.4;

// Line-height
$lineHeightDefault: 2.5rem;
$lineHeightTable: normal;

// Fontfamily
$font: '';
$fontIcon: "Font Awesome 5 Pro";
$fontSpecial: $font;

// Personalized Primary colors
$colorPrimary: #333333;
$colorSecondary: #62A824;
$colorTertiary: #FF9800;
$colorFontDefault: $colorPrimary;
$colorTitleDefault: #484848;
$colorTextLight:#ccc;
$colorLightSecondary: #73B13C;
$colorDarkSecondary: #E8710C;
$colorBeige: #F9F7F1;

// Personalized colors
$colorBorder: #f0f0f0;
$colorInputBorder: #484C5261;
$colorButton: $colorTertiary;
$colorPlaceholder:#333;
$colorFooter: #2A2A29;

// Grey
$colorLighterGrey: #EBEBEB;
$colorLightGrey: #F5F5F5;
$colorGrey: #8F8F8F;
$colorDarkGrey: #707070;
$colorSwatchGrey: #D5D5D5;

// Default colors
$colorError: #E63636;
$colorSuccess: #53a653;
$colorSuperSuccess: #408140;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorWhite: #fff;
$colorBlack: #000;
$colorRed: #E63636;




// icons: use fontawesome pro unicode - leave empty for no icon
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f2ed";
$iconEdit: "\f040";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconArrowNext: "\f054";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconMenu: "\f0c9";
$iconSearch: "\f002";
$iconSortArrow: "\f0dd";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
$iconSearch: "\f002";
$iconCart: url($iconDir + "shopping-cart.svg");
$iconCartWhite: url($iconDir + "shopping-cart_white.svg");
$iconCartBorder: url($iconDir + "shopping-cart_outline.svg");
$iconBag: "\f290";
$iconPlus: "\f067";
$iconMinus: "\f068";
$iconEye: "\f06e";
$iconPhone: "\f095";
$iconLetter: "\f0e0";
$iconStar: "\f005";
$iconCalendar: "\f133";
$iconCrosshairs: "\f05b";
$iconRefresh: "\f2f1";
$iconDownload: url($iconDir + "download.svg");

